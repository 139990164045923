import * as React from 'react'
import { GlobalHotKeys } from 'react-hotkeys'
import { styled, alpha } from '@mui/material/styles'
import Button, { ButtonProps } from '@mui/material/Button'
import Box from '@mui/material/Box'
import CloseIcon from '@mui/icons-material/Close'
import DescriptionIcon from '@mui/icons-material/Description'
import IconButton from '@mui/material/IconButton'
import InputBase from '@mui/material/InputBase'
import SearchIcon from '@mui/icons-material/Search'
import Paper from '@mui/material/Paper'
import Divider from '@mui/material/Divider'
import Dialog, { DialogProps } from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import ListSubheader from '@mui/material/ListSubheader'
import LinearProgress from '@mui/material/LinearProgress'
import Chip from '@mui/material/Chip'
import { useSnackbar } from 'notistack'
import Api from '@api'
import SettingContext from '@contexts/SettingContext'
import axios from 'axios'
import { getLanguageFont, getLanguageDirection } from '@helper'
import ClearAllIcon from '@mui/icons-material/ClearAll'
import GotoAya from '@components/GotoAya'
import {
  ManzilArray,
  HizbArray,
  JuzArray,
  PageArray,
} from '@components/GotoData'
import Goto from '@components/Goto'

const Search = styled(Button)<ButtonProps>(({ theme }) => ({
  color: 'white',
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  fontWeight: 'unset',
  padding: theme.spacing(1, 1, 1, 1),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('xs')]: {
    paddingLeft: theme.spacing(2),
    width: '8ch',
  },
  [theme.breakpoints.up('sm')]: {
    paddingLeft: theme.spacing(2),
    width: '8ch',
  },
  [theme.breakpoints.up('md')]: {
    width: '20ch',
    marginRight: theme.spacing(2),
  },
  textTransform: 'none',
  justifyContent: 'flex-start',
}))

const StyledDialog = styled(Dialog)<DialogProps>(({ theme }) => ({
  '.MuiDialogContent-root': {
    height: '70vh',
  },
  '& .MuiBackdrop-root': {
    backgroundColor: 'rgba(32, 38, 45, 0.2)',
    backdropFilter: 'blur(2px)',
  },
}))

// const SearchOptions = (
//   <React.Fragment>
//     <Box display="flex" alignItems="center" mt={1}>
//       <Box mr={2}>
//         <FormLabel component="legend">Operator</FormLabel>
//       </Box>
//       <RadioGroup name="operator">
//         <Box display="flex">
//           <FormControlLabel
//             value={OPERATORS.AND}
//             control={<Radio />}
//             label="AND"
//           />
//           <FormControlLabel
//             value={OPERATORS.OR}
//             control={<Radio />}
//             label="OR"
//           />
//         </Box>
//       </RadioGroup>
//     </Box>

//     <Box display="flex" mt={2}>
//       <Box mr={1}>
//         <Chip avatar={<Avatar>1</Avatar>} label="Quran Text" color="primary" />
//       </Box>
//       <Box>
//         <Chip avatar={<Avatar>2</Avatar>} label="Tafsir" variant="outlined" />
//       </Box>
//     </Box>
//   </React.Fragment>
// )

const CancelToken = axios.CancelToken
let source

export default function SearchDialog() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [open, setOpen] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false)
  const [searchText, setSearchText] = React.useState('')
  const [result, setResult] = React.useState([])

  const { currentSetting, updateCurrentSetting } = React.useContext(
    SettingContext
  ) as SettingContextType

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  async function handleLoadData() {
    if (!currentSetting?.translationTypes) return
    if (currentSetting?.translationTypes.length == 0) return
    const translations = currentSetting.translationTypes.join()

    if (source) source.cancel()
    source = CancelToken.source()
    setIsLoading(true)
    try {
      const http = await Api()
      const res = await http.get('search', {
        params: {
          searchText: searchText,
          page: 0,
          pageSize: 20,
          translations,
        },
        cancelToken: source.token,
      })
      const data = res.data
      setResult(data)
    } catch (error) {
      if (axios.isCancel(error)) return
      console.error(error)
      enqueueSnackbar('Error on loading', {
        variant: 'error',
      })
    }
    setIsLoading(false)
  }

  const handleGoAya = (sura: number, aya: number) => {
    updateCurrentSetting({
      ...currentSetting,
      currentSura: sura,
      currentAya: aya,
    })
    handleClose()
  }

  React.useEffect(() => {
    if (searchText) handleLoadData()
    else setResult([])
  }, [searchText])

  const keyMap = { SHOW_SEARCH: 'command+k' }
  const handlers = { SHOW_SEARCH: handleClickOpen }

  return (
    <React.Fragment>
      <GlobalHotKeys keyMap={keyMap} handlers={handlers} />
      <Search onClick={handleClickOpen}>
        <SearchIcon sx={{ mr: 0.5, ml: 0.5 }} />
        <Box sx={{ display: { xs: 'none', sm: 'none', md: 'flex' } }}>
          Search...
          <Chip sx={{ ml: 1, mr: 1 }} size="small" label="⌘K" color="primary" />
        </Box>
      </Search>
      {open && (
        <StyledDialog open={open} maxWidth="sm" fullWidth onClose={handleClose}>
          <DialogTitle>
            <Paper
              elevation={0}
              component="form"
              sx={{
                p: '2px 4px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <SearchIcon fontSize="large" color="primary" />
              <InputBase
                autoFocus={true}
                sx={{ ml: 1, flex: 1, fontSize: '1em' }}
                placeholder="Search..."
                inputProps={{ 'aria-label': 'search' }}
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
              <IconButton
                type="submit"
                sx={{ p: '10px' }}
                aria-label="search"
                onClick={() => setSearchText('')}
              >
                <ClearAllIcon />
              </IconButton>
              <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
              <IconButton
                color="primary"
                sx={{ p: '10px' }}
                aria-label="close"
                onClick={handleClose}
              >
                <CloseIcon />
              </IconButton>
            </Paper>
          </DialogTitle>
          {isLoading ? (
            <LinearProgress sx={{ height: '3px' }} />
          ) : (
            <Divider sx={{ height: '3px' }} />
          )}

          <DialogContent>
            <Box mx={-3} mt={-3}>
              <List>
                {result &&
                  result.categories &&
                  result.categories.length > 0 &&
                  result.categories.map((category, ci) => (
                    <React.Fragment key={ci}>
                      <ListSubheader disableSticky>
                        <Box pl={1}>
                          {category.name} ({category.count})
                        </Box>
                      </ListSubheader>
                      {category.items &&
                        category.items.length > 0 &&
                        category.items.map((item, ii) => (
                          <ListItem
                            key={ii}
                            disablePadding
                            sx={{
                              direction: getLanguageDirection(item.language),
                              fontFamily: getLanguageFont(item.language),
                            }}
                          >
                            <ListItemButton
                              onClick={() => handleGoAya(item.sura, item.aya)}
                            >
                              <ListItemIcon>
                                <DescriptionIcon />
                              </ListItemIcon>
                              <ListItemText
                                style={{
                                  textAlign:
                                    getLanguageDirection(item.language) == 'rtl'
                                      ? 'right'
                                      : 'left',
                                }}
                                primary={item.title}
                                secondary={item.description}
                              />
                            </ListItemButton>
                          </ListItem>
                        ))}
                    </React.Fragment>
                  ))}
              </List>
            </Box>
            <GotoAya onGo={handleClose} />
            <Goto title="Manzil" array={ManzilArray} onGo={handleClose} />
            <Goto title="Juz" array={JuzArray} onGo={handleClose} />
            <Goto title="Hizb" array={HizbArray} onGo={handleClose} />
            <Goto title="Page" array={PageArray} onGo={handleClose} />
          </DialogContent>
        </StyledDialog>
      )}
    </React.Fragment>
  )
}

import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import {
  getAuth,
  onAuthStateChanged,
  signOut,
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
} from 'firebase/auth'

const firebaseConfig = {
  apiKey: 'AIzaSyAVfJL5G1bQ-AEffaqWR6cKtAkmviTkavo',
  authDomain: 'nx-quran.firebaseapp.com',
  projectId: 'nx-quran',
  storageBucket: 'nx-quran.appspot.com',
  messagingSenderId: '903826275251',
  appId: '1:903826275251:web:e0230d005006cf674029c6',
  measurementId: 'G-F421ZF36H4',
}

const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)
const auth = getAuth()

enum AuthTypes {
  Google,
  Facebook,
}

interface ISignOut {
  authType: AuthTypes
  updateCurrentUser: any
}

interface ISignIn {
  authType: AuthTypes
}

const SignOut = async (): Promise<void> => {
  await signOut(auth)
}

const SignIn = async (model: ISignIn) => {
  let socialMediaProvider: any
  switch (model.authType) {
    case AuthTypes.Google:
      socialMediaProvider = new GoogleAuthProvider()
      break
    case AuthTypes.Facebook:
      socialMediaProvider = new FacebookAuthProvider()
      break
  }

  try {
    const result = await signInWithPopup(auth, socialMediaProvider)
    // switch (model.authType) {
    //   case AuthTypes.Google:
    //   // if (result?.user)
    //   //   model.updateCurrentUser({
    //   //     token: GoogleAuthProvider.credentialFromResult(result)?.accessToken,
    //   //     displayName: result.user.displayName,
    //   //     email: result.user.email,
    //   //     photoURL: result.user.photoURL,
    //   //   })
    //   case AuthTypes.Facebook:
    //   //throw new Error('Not implemented')
    //   // return {
    //   //   user: result.user,
    //   //   token: FacebookAuthProvider.credentialFromResult(result)?.accessToken,
    //   // }
    // }
  } catch (error: any) {
    switch (model.authType) {
      case AuthTypes.Google:
      // return {
      //   error,
      //   credential: GoogleAuthProvider.credentialFromError(error),
      // }
      case AuthTypes.Facebook:
      // return {
      //   error,
      //   credential: FacebookAuthProvider.credentialFromError(error),
      // }
    }
  }
}

const getCurrentToken = async () => {
  if (!auth) return null
  const currentUser = auth.currentUser
  if (!currentUser) return null
  const token = await currentUser.getIdToken(true)
  return token
}

const getCurrentUser = () => {
  if (!auth) return null
  const currentUser = auth.currentUser
  return currentUser
}

export {
  SignIn,
  SignOut,
  getCurrentToken,
  getCurrentUser,
  auth,
  onAuthStateChanged,
  AuthTypes,
}

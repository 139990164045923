import React from 'react'
import IconButton from '@mui/material/IconButton'
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder'
import BookmarkAddedIcon from '@mui/icons-material/BookmarkAdded'
import Api from '@api'
import { useSnackbar } from 'notistack'

export default function Bookmark(props: IBookmark) {
  const { enqueueSnackbar } = useSnackbar()
  const [isBookmarked, setIsBookmarked] = React.useState(props.bookmarked)
  const [isSubmitting, setIsSubmitting] = React.useState(false)

  const handleClick = async () => {
    setIsSubmitting(true)
    const newState = !isBookmarked
    setIsBookmarked(newState)
    try {
      const http = await Api()
      const res = await http.post('user/bookmark', {
        sura: props.sura,
        aya: props.aya,
        isMarked: newState,
      })
    } catch (error) {
      console.error(error)
      enqueueSnackbar('Error on setting bookmark', {
        variant: 'error',
      })
    }
    setIsSubmitting(false)
  }

  return (
    <IconButton
      aria-label="add to bookmarks"
      onClick={async () => await handleClick()}
      disabled={isSubmitting}
    >
      {isBookmarked ? (
        <BookmarkAddedIcon color="success" />
      ) : (
        <BookmarkBorderIcon />
      )}
    </IconButton>
  )
}

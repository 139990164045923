import * as React from 'react'
import MenuItem from '@mui/material/MenuItem'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Slide from '@mui/material/Slide'
import { TransitionProps } from '@mui/material/transitions'
import GoogleIcon from '@mui/icons-material/Google'
import Box from '@mui/material/Box'
import { SignIn, SignOut, AuthTypes, getCurrentUser } from '@services/Auth'

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children?: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

declare interface ILoginProps {
  onClick: () => void
}

export default function Login(LoginProps: ILoginProps) {
  const currentUser = getCurrentUser()
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    LoginProps.onClick && LoginProps.onClick()
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleGoogleAuth = async () => {
    await SignIn({ authType: AuthTypes.Google })
    handleClose()
  }

  const handleSignOut = async () => {
    LoginProps.onClick && LoginProps.onClick()
    await SignOut()
  }

  return (
    <React.Fragment>
      {currentUser ? (
        <MenuItem onClick={handleSignOut}>Logout</MenuItem>
      ) : (
        <MenuItem onClick={handleClickOpen}>Login</MenuItem>
      )}
      {open && (
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>Login with social media accounts</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Using existing login information from a social network provider
              like Facebook, Twitter, or Google, the user can sign into this
              website instead of creating a new account.
            </DialogContentText>
            <Box
              sx={{ display: 'flex', pt: 5, pb: 5, justifyContent: 'center' }}
            >
              <Button onClick={handleGoogleAuth}>
                <GoogleIcon sx={{ mr: 2 }} />
                Log in with Google
              </Button>
            </Box>
          </DialogContent>
        </Dialog>
      )}
    </React.Fragment>
  )
}

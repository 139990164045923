import * as React from 'react'
import Box from '@mui/material/Box'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'
import Api from '@api'
import AppMenuTranslationTypes from '@components/AppMenuTranslationTypes'

export default function AppMenuTranslation() {
  const [open, setOpen] = React.useState(true)
  const [translationTypes, setTranslationTypes] =
    React.useState<ITranslationType[]>()
  const [languages, setLanguages] = React.useState([])

  function onlyUnique(value: string, index: number, self: any) {
    return self.indexOf(value) === index
  }

  async function handleLoadData() {
    const http = await Api()
    const res = await http.get(`translations/types`)
    const data = res.data
    setTranslationTypes(data)
    setLanguages(
      data.map((r: ITranslationType) => r.language).filter(onlyUnique)
    )
  }

  React.useEffect(() => {
    ;(async () => await handleLoadData())()
  }, [])

  return (
    <Box
      sx={{
        bgcolor: open ? 'primary' : null,
        pb: open ? 2 : 0,
      }}
    >
      <ListItemButton
        alignItems="flex-start"
        onClick={() => setOpen(!open)}
        sx={{
          px: 3,
          pt: 2.5,
          pb: open ? 0 : 2.5,
          '&:hover, &:focus': { '& svg': { opacity: open ? 1 : 0 } },
        }}
      >
        <ListItemText
          primary="Translations"
          primaryTypographyProps={{
            fontSize: 15,
            fontWeight: 'medium',
            lineHeight: '20px',
            mb: '2px',
          }}
          secondary="See selected translations together"
          secondaryTypographyProps={{
            noWrap: true,
            fontSize: 12,
            lineHeight: '16px',
            color: open ? 'rgba(0,0,0,0)' : '',
          }}
          sx={{ my: 0 }}
        />
        <KeyboardArrowDown
          sx={{
            mr: -1,
            opacity: 0,
            transform: open ? 'rotate(-180deg)' : 'rotate(0)',
            transition: '0.2s',
          }}
        />
      </ListItemButton>
      {open &&
        translationTypes &&
        languages &&
        languages.length > 0 &&
        languages.map((language) => {
          const types = translationTypes.filter((r) => r.language == language)
          return (
            <AppMenuTranslationTypes
              key={language}
              language={language}
              translationTypes={types}
            />
          )
        })}
    </Box>
  )
}

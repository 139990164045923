import React from 'react'
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import Input from '@mui/material/Input'
import InputLabel from '@mui/material/InputLabel'
import { Button } from '@mui/material'
import SettingContext from '@contexts/SettingContext'

export default function Goto(props: IGotoProps) {
  const { currentSetting, updateCurrentSetting } = React.useContext(
    SettingContext
  ) as SettingContextType

  const [value, setValue] = React.useState('1')

  const handleGo = () => {
    const valueNumber = parseInt(value)
    const suraNumber = props.array[valueNumber - 1][0]
    const ayaNumber = props.array[valueNumber - 1][1]
    updateCurrentSetting({
      ...currentSetting,
      currentSura: suraNumber,
      currentAya: ayaNumber,
      positionChanged: Date.now(),
    })
    props.onGo && props.onGo()
  }

  const handleValueChange = (e: any) => {
    if (isNaN(e.target.value)) return
    let valueNumber = 1
    if (e.target.value === '') {
      setValue('')
      return
    }
    valueNumber = parseInt(e.target.value)
    if (valueNumber < 1) valueNumber = 1
    if (valueNumber > props.array.length) valueNumber = props.array.length

    setValue(valueNumber.toString())
  }

  return (
    <div>
      <Box
        component="form"
        sx={{
          '& > :not(style)': { m: 1 },
          width: 200,
          display: 'flex',
          alignItems: 'center',
        }}
        noValidate
        autoComplete="off"
      >
        <FormControl variant="standard">
          <InputLabel htmlFor="valueEl">{props.title}</InputLabel>
          <Input id="valueEl" value={value} onChange={handleValueChange} />
          <FormHelperText>From 1 To {props.array.length}</FormHelperText>
        </FormControl>

        <Button variant="outlined" onClick={handleGo} disabled={value === ''}>
          Go
        </Button>
      </Box>
    </div>
  )
}

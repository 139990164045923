import * as React from 'react'
import Box from '@mui/material/Box'
import { styled, ThemeProvider, createTheme } from '@mui/material/styles'
import Divider from '@mui/material/Divider'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Paper from '@mui/material/Paper'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import ArrowLeft from '@mui/icons-material/ArrowLeft'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'
import Settings from '@mui/icons-material/Settings'
import CloseIcon from '@mui/icons-material/Close'
import Api from '@api'
import AppMenuTranslation from '@components/AppMenuTranslation'

const FireNav = styled(List)<{ component?: React.ElementType }>({
  '& .MuiListItemButton-root': {
    paddingLeft: 24,
    paddingRight: 24,
  },
  '& .MuiListItemIcon-root': {
    minWidth: 0,
    marginRight: 16,
  },
  '& .MuiSvgIcon-root': {
    fontSize: 20,
  },
})

export default function AppMenu({ onClose }) {
  return (
    <Box sx={{ display: 'flex' }}>
      <Paper elevation={0} sx={{ maxWidth: 256 }}>
        <FireNav component="nav" disablePadding>
          <ListItem component="div" disablePadding>
            <ListItemButton sx={{ height: 56 }}>
              <ListItemIcon>
                <Settings color="primary" />
              </ListItemIcon>
              <ListItemText
                primary="Quran Explorer"
                primaryTypographyProps={{
                  color: 'primary',
                  fontWeight: 'medium',
                  variant: 'body2',
                }}
              />
            </ListItemButton>
            <Tooltip title="Quran Explorer">
              <IconButton
                onClick={onClose}
                size="large"
                sx={{
                  '& svg': {
                    color: 'rgba(251,118,0,0.8)',
                    transition: '0.2s',
                    transform: 'translateX(0) rotate(0)',
                  },
                  '&:hover, &:focus': {
                    bgcolor: 'unset',
                    '& svg:last-of-type': {
                      right: 0,
                      opacity: 1,
                    },
                  },
                  '&:after': {
                    content: '""',
                    position: 'absolute',
                    height: '80%',
                    display: 'block',
                    left: 0,
                    width: '1px',
                    bgcolor: 'divider',
                  },
                }}
              >
                <CloseIcon />
                <ArrowLeft
                  sx={{ position: 'absolute', right: 4, opacity: 0 }}
                />
              </IconButton>
            </Tooltip>
          </ListItem>
          <Divider />
          <AppMenuTranslation />
        </FireNav>
      </Paper>
    </Box>
  )
}

import * as React from 'react'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Collapse from '@mui/material/Collapse'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import LanguageIcon from '@mui/icons-material/Language'
import Checkbox from '@mui/material/Checkbox'
import SettingContext from '@contexts/SettingContext'

export default function AppMenuTranslationList({ language, translationTypes }) {
  const { currentSetting, updateCurrentSetting } = React.useContext(
    SettingContext
  ) as SettingContextType

  const [open, setOpen] = React.useState(false)

  const handleToggle = (value: number) => () => {
    const currentIndex = currentSetting.translationTypes.indexOf(value)
    const newChecked = [...currentSetting.translationTypes]

    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }

    updateCurrentSetting({ ...currentSetting, translationTypes: newChecked })
  }

  const handleClick = () => {
    setOpen(!open)
  }
  return (
    <React.Fragment>
      <ListItemButton sx={{ py: 0, minHeight: 32 }} onClick={handleClick}>
        <ListItemIcon sx={{ color: 'inherit' }}>
          <LanguageIcon />
        </ListItemIcon>
        <ListItemText primary={language} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {translationTypes.map((r) => (
            <ListItemButton
              key={r.translationTypeId}
              sx={{ pl: 4 }}
              role={undefined}
              onClick={handleToggle(r.translationTypeId)}
            >
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={
                    currentSetting.translationTypes.indexOf(
                      r.translationTypeId
                    ) !== -1
                  }
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': r.translationTypeId }}
                />
              </ListItemIcon>

              <ListItemText primary={r.name} />
            </ListItemButton>
          ))}
        </List>
      </Collapse>
    </React.Fragment>
  )
}

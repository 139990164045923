import axios from 'axios'
import { getCurrentToken } from '@services/Auth'

const Api = async () => {
  let headers = null

  const token = await getCurrentToken()
  if (token)
    headers = {
      Authorization: `Bearer ${token}`,
    }

  const url =
    !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
      ? 'https://localhost:5001'
      : 'https://api.quranresearch.app'

  return axios.create({
    baseURL: url,
    headers,
  })
}

export default Api

import React from 'react'
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import Input from '@mui/material/Input'
import InputLabel from '@mui/material/InputLabel'
import { Button } from '@mui/material'
import SettingContext from '@contexts/SettingContext'

export default function GotoAya(props: IGotoAyaProps) {
  const { currentSetting, updateCurrentSetting } = React.useContext(
    SettingContext
  ) as SettingContextType

  const [sura, setSura] = React.useState(
    currentSetting?.currentSura?.toString() ?? ''
  )
  const [aya, setAya] = React.useState(
    currentSetting?.currentAya?.toString() ?? ''
  )

  const maxAya = [
    7, 286, 200, 176, 120, 165, 206, 75, 129, 109, 123, 111, 43, 52, 99, 128,
    111, 110, 98, 135, 112, 78, 118, 64, 77, 227, 93, 88, 69, 60, 34, 30, 73,
    54, 45, 83, 182, 88, 75, 85, 54, 53, 89, 59, 37, 35, 38, 29, 18, 45, 60, 49,
    62, 55, 78, 96, 29, 22, 24, 13, 14, 11, 11, 18, 12, 12, 30, 52, 52, 44, 28,
    28, 20, 56, 40, 31, 50, 40, 46, 42, 29, 19, 36, 25, 22, 17, 19, 26, 30, 20,
    15, 21, 11, 8, 8, 19, 5, 8, 8, 11, 11, 8, 3, 9, 5, 4, 7, 3, 6, 3, 5, 4, 5,
    6,
  ]

  const handleGo = () => {
    updateCurrentSetting({
      ...currentSetting,
      currentSura: parseInt(sura),
      currentAya: parseInt(aya),
      positionChanged: Date.now(),
    })
    props.onGo && props.onGo()
  }

  const handleSuraChange = (e: any) => {
    if (isNaN(e.target.value)) return
    let suraNumber = 1
    if (e.target.value === '') {
      setSura('')
      return
    }
    suraNumber = parseInt(e.target.value)
    if (suraNumber < 1) suraNumber = 1
    if (suraNumber > 114) suraNumber = 114

    setSura(suraNumber.toString())
  }

  const handleAyaChange = (e: any) => {
    if (isNaN(e.target.value)) return
    let ayaNumber = 1
    if (e.target.value === '') {
      setAya('')
      return
    }
    ayaNumber = parseInt(e.target.value)
    if (ayaNumber < 1) ayaNumber = 1
    const maxAya = getMaxAya()
    if (ayaNumber > maxAya) ayaNumber = maxAya
    setAya(ayaNumber.toString())
  }

  const getMaxAya = (): number => {
    const suraNumber = parseInt(sura)
    if (suraNumber >= 1 && suraNumber <= 114) return maxAya[suraNumber - 1]
    return 0
  }

  return (
    <div>
      <Box
        component="form"
        sx={{
          '& > :not(style)': { m: 1 },
          width: 300,
          display: 'flex',
          alignItems: 'center',
        }}
        noValidate
        autoComplete="off"
      >
        <FormControl variant="standard">
          <InputLabel htmlFor="sura">Sura</InputLabel>
          <Input id="sura" value={sura} onChange={handleSuraChange} />
          <FormHelperText>From 1 To 114</FormHelperText>
        </FormControl>

        <FormControl variant="standard">
          <InputLabel htmlFor="aya">Aya</InputLabel>
          <Input
            id="aya"
            value={aya}
            onChange={handleAyaChange}
            disabled={sura === ''}
          />

          {sura !== '' && (
            <FormHelperText>From 1 To {getMaxAya()}</FormHelperText>
          )}
        </FormControl>

        <Button
          variant="outlined"
          onClick={handleGo}
          disabled={sura === '' || aya === ''}
        >
          Go
        </Button>
      </Box>
    </div>
  )
}

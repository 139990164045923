import React from 'react'
import useSpeechToText from 'react-hook-speech-to-text'
import Button from '@mui/material/Button'
import MicNoneIcon from '@mui/icons-material/MicNone'
import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice'
import insertTextAtCursor from 'insert-text-at-cursor'

type ResultType = {
  speechBlob?: Blob
  timestamp: number
  transcript: string
}

interface ISpeechProps {
  el: any
}

export default function Speech(props: ISpeechProps) {
  const {
    error,
    interimResult,
    isRecording,
    results,
    startSpeechToText,
    stopSpeechToText,
  } = useSpeechToText({
    continuous: true,
    crossBrowser: true,
    googleApiKey: 'AIzaSyABiuSYFnVW8wbIEehJGj99cKlTXUXcmKs',
    useLegacyResults: false,
    googleCloudRecognitionConfig: {
      languageCode: 'fa-IR',
    },
    speechRecognitionProperties: {
      lang: 'fa-IR',
      interimResults: true, // Allows for displaying real-time speech results
    },
  })

  if (error) return <p>Web Speech API is not available in this browser</p>

  const insertAtCursor = (text: string) => {
    insertTextAtCursor(props.el.current, text)
  }

  return (
    <div>
      <div>
        <Button onClick={isRecording ? stopSpeechToText : startSpeechToText}>
          {isRecording ? <MicNoneIcon /> : <KeyboardVoiceIcon />}
          {isRecording ? 'Stop Recording' : 'Start Recording'}
        </Button>
        {interimResult && <span>{interimResult}</span>}
      </div>
      {results.map((result) => (
        <Button
          key={result.timestamp}
          onClick={(e) => insertAtCursor(result.transcript)}
        >
          {result.transcript}
        </Button>
      ))}
    </div>
  )
}

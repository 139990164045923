import * as React from 'react'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import basmala from '@assets/basmala.svg'

export default function Aya() {
  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <img
          style={{ width: '100%', maxWidth: '500px' }}
          src={basmala}
          alt="basmala"
        />
      </Box>
      <Divider />
    </React.Fragment>
  )
}

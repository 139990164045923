import * as React from 'react'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'
import Slide from '@mui/material/Slide'
import { TransitionProps } from '@mui/material/transitions'
import TextField from '@mui/material/TextField'
import EditIcon from '@mui/icons-material/Edit'
import Api from '@api'
import { useSnackbar } from 'notistack'
import LinearProgress from '@mui/material/LinearProgress'
import Speech from '@components/Speech'
import Editor from '@components/RemoteMentionEditor'
import { getLanguageFont, getLanguageDirection } from '@helper'

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function TafsirEditDialog(props: IAyaTafsirEditProps) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [open, setOpen] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(true)
  const [isSubmitting, setIsSubmitting] = React.useState(false)

  const [text, setText] = React.useState<string>('')
  const inputRef = React.useRef()

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  async function handleLoadData() {
    try {
      setIsLoading(true)
      const http = await Api()
      const res = await http.get(`tafsirs`, {
        params: {
          tafsirTypeId: props.tafsirTypeId,
          sura: props.sura,
          aya: props.aya,
        },
      })
      const data = res.data
      setText(data.text)
      setIsLoading(false)
    } catch (error) {
      console.error(error)
      enqueueSnackbar('Error on loading tafsir', {
        variant: 'error',
      })
    }
  }

  React.useEffect(() => {
    ;(async () => {
      if (open) await handleLoadData()
    })()
  }, [open])

  async function handleSaveData() {
    setIsSubmitting(true)
    try {
      const http = await Api()
      const res = await http.post('tafsirs', {
        tafsirTypeId: props.tafsirTypeId,
        sura: props.sura,
        aya: props.aya,
        text,
      })

      enqueueSnackbar('Saved successfully.', {
        variant: 'success',
      })
      setIsSubmitting(false)
      props.onUpdate && props.onUpdate(text)
      handleClose()
    } catch (error) {
      console.error(error)
      enqueueSnackbar('Error on saving tafsir', {
        variant: 'error',
      })
      setIsSubmitting(false)
    }
  }

  return (
    <React.Fragment>
      <IconButton aria-label="edit" onClick={handleClickOpen}>
        <EditIcon />
      </IconButton>
      {open && (
        <Dialog
          fullScreen
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          {isLoading ? (
            <LinearProgress />
          ) : (
            <React.Fragment>
              <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                  <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                  >
                    <CloseIcon />
                  </IconButton>
                  <Typography
                    sx={{ ml: 2, flex: 1 }}
                    variant="h6"
                    component="div"
                  >
                    Edit Tafsir
                  </Typography>
                  <Button color="inherit" onClick={handleSaveData}>
                    Save
                  </Button>
                </Toolbar>
              </AppBar>
              <Box sx={{ m: 3 }}>
                <Editor
                  rawValue={text ?? ''}
                  onRawChange={(html: string) => {
                    setText(html)
                  }}
                />
              </Box>
            </React.Fragment>
          )}
        </Dialog>
      )}
    </React.Fragment>
  )
}

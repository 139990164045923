import * as React from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import { styled, alpha } from '@mui/material/styles'
import InputBase from '@mui/material/InputBase'
import SearchIcon from '@mui/icons-material/Search'
import SettingContext from '@contexts/SettingContext'
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver'
import Popper from '@mui/material/Popper'

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(1),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('xs')]: {
    marginLeft: theme.spacing(1),
    width: 150,
  },
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 180,
  },
  [theme.breakpoints.up('md')]: {
    marginLeft: theme.spacing(1),
    width: 200,
  },
}))

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('xs')]: {
      paddingLeft: `calc(1em + ${theme.spacing(0)})`,
    },
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(1),
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    },
  },
}))

const CustomPopper = function (props: any) {
  return <Popper {...props} style={{ width: 300 }} placement="bottom-start" />
}

export default function ReciterSelector() {
  const { currentSetting, updateCurrentSetting } = React.useContext(
    SettingContext
  ) as SettingContextType

  const [value, setValue] = React.useState<any | null>(reciters[0])
  //48: Parhizgar

  const handleChange = (newValue: any) => {
    const selectedReciter = newValue?.subfolder ?? 'Parhizgar_48kbps'
    setValue(newValue)
    updateCurrentSetting({ ...currentSetting, currentReciter: selectedReciter })
  }
  return (
    <Search>
      <SearchIconWrapper sx={{ display: { xs: 'none', md: 'flex' } }}>
        <RecordVoiceOverIcon />
      </SearchIconWrapper>
      <Autocomplete
        freeSolo
        disableClearable
        autoHighlight
        clearOnEscape
        selectOnFocus
        size="small"
        id="combo-box-reciter"
        options={reciters}
        PopperComponent={CustomPopper}
        sx={{
          width: 1,
        }}
        value={value}
        onChange={(event: any, newValue: any) => {
          handleChange(newValue)
        }}
        renderInput={(params) => {
          const { InputLabelProps, InputProps, ...rest } = params
          return (
            <StyledInputBase
              placeholder="Reciter"
              inputProps={{ 'aria-label': 'reciter' }}
              {...params.InputProps}
              {...rest}
            />
          )
        }}
      />
    </Search>
  )
}

//https://everyayah.com/data/recitations.js
//Note: The json is transfered to array
const reciters = [
  {
    subfolder: 'Parhizgar_48kbps',
    label: 'Parhizgar',
  },
  {
    subfolder: 'Abdul_Basit_Murattal_192kbps',
    label: 'Abdul Basit Murattal',
  },
  {
    subfolder: 'Abdul_Basit_Mujawwad_128kbps',
    label: 'Abdul Basit Mujawwad',
  },
  {
    subfolder: 'Abdullah_Basfar_192kbps',
    label: 'Abdullah Basfar',
  },
  {
    subfolder: 'Abdurrahmaan_As-Sudais_192kbps',
    label: 'Abdurrahmaan As-Sudais',
  },
  {
    subfolder: 'AbdulSamad_64kbps_QuranExplorer.Com',
    label: 'AbdulSamad',
  },
  {
    subfolder: 'Abu_Bakr_Ash-Shaatree_128kbps',
    label: 'Abu Bakr Ash-Shaatree',
  },
  {
    subfolder: 'Ahmed_ibn_Ali_al-Ajamy_128kbps_ketaballah.net',
    label: 'Ahmed ibn Ali al-Ajamy',
  },
  {
    subfolder: 'Alafasy_128kbps',
    label: 'Alafasy',
  },
  {
    subfolder: 'Ghamadi_40kbps',
    label: 'Ghamadi',
  },
  {
    subfolder: 'Hani_Rifai_192kbps',
    label: 'Hani Rifai',
  },
  {
    subfolder: 'Husary_128kbps',
    label: 'Husary',
  },
  {
    subfolder: 'Husary_128kbps_Mujawwad',
    label: 'Husary Mujawwad',
  },
  {
    subfolder: 'Hudhaify_128kbps',
    label: 'Hudhaify',
  },
  {
    subfolder: 'MaherAlMuaiqly128kbps',
    label: 'Maher Al Muaiqly',
  },
  {
    subfolder: 'Menshawi_32kbps',
    label: 'Menshawi',
  },
  {
    subfolder: 'Minshawy_Mujawwad_192kbps',
    label: 'Minshawy Mujawwad',
  },
  {
    subfolder: 'Minshawy_Murattal_128kbps',
    label: 'Minshawy Murattal',
  },
  {
    subfolder: 'Mohammad_al_Tablaway_128kbps',
    label: 'Mohammad al Tablaway',
  },
  {
    subfolder: 'Muhammad_Ayyoub_128kbps',
    label: 'Muhammad Ayyoub',
  },
  {
    subfolder: 'Muhammad_Jibreel_128kbps',
    label: 'Muhammad Jibreel',
  },
  {
    subfolder: 'Mustafa_Ismail_48kbps',
    label: 'Mustafa Ismail',
  },
  {
    subfolder: 'Saood_ash-Shuraym_128kbps',
    label: 'Saood bin Ibraaheem Ash-Shuraym',
  },
  {
    subfolder: 'English/Sahih_Intnl_Ibrahim_Walk_192kbps',
    label:
      '(English) Translated by Sahih International Recited by Ibrahim Walk',
  },
  {
    subfolder: 'MultiLanguage/Basfar_Walk_192kbps',
    label: 'MultiLanguage/Basfar Walk',
  },
  {
    subfolder: 'translations/Makarem_Kabiri_16Kbps',
    label: '(Persian) Translated by Makarem Recited by Kabiri',
  },
  {
    subfolder: 'translations/Fooladvand_Hedayatfar_40Kbps',
    label: '(Persian) Translated by Fooladvand Recited by Hedayatfar',
  },
  {
    subfolder: 'translations/azerbaijani/balayev',
    label: 'Balayev',
  },
  {
    subfolder: 'Salaah_AbdulRahman_Bukhatir_128kbps',
    label: 'Salaah AbdulRahman Bukhatir',
  },
  {
    subfolder: 'Muhsin_Al_Qasim_192kbps',
    label: 'Muhsin Al Qasim',
  },
  {
    subfolder: 'Abdullaah_3awwaad_Al-Juhaynee_128kbps',
    label: 'Abdullaah 3awwaad Al-Juhaynee',
  },
  {
    subfolder: 'Salah_Al_Budair_128kbps',
    label: 'Salah Al Budair',
  },
  {
    subfolder: 'Abdullah_Matroud_128kbps',
    label: 'Abdullah Matroud',
  },
  {
    subfolder: 'Ahmed_Neana_128kbps',
    label: 'Ahmed Neana',
  },
  {
    subfolder: 'Muhammad_AbdulKareem_128kbps',
    label: 'Muhammad AbdulKareem',
  },
  {
    subfolder: 'khalefa_al_tunaiji_64kbps',
    label: 'Khalefa Al-Tunaiji',
  },
  {
    subfolder: 'mahmoud_ali_al_banna_32kbps',
    label: 'Mahmoud Ali Al-Banna',
  },
  {
    subfolder: 'warsh/warsh_ibrahim_aldosary_128kbps',
    label: '(Warsh) Ibrahim Al-Dosary',
  },
  {
    subfolder: 'warsh/warsh_yassin_al_jazaery_64kbps',
    label: '(Warsh) Yassin Al-Jazaery',
  },
  {
    subfolder: 'warsh/warsh_Abdul_Basit_128kbps',
    label: '(Warsh) Abdul Basit',
  },
  {
    subfolder: 'translations/urdu_shamshad_ali_khan_46kbps',
    label: '(Urdu) Shamshad Ali Khan',
  },
  {
    subfolder: 'Karim_Mansoori_40kbps',
    label: 'Karim Mansoori (Iran)',
  },
  {
    subfolder: 'Husary_Muallim_128kbps',
    label: 'Husary (Muallim)',
  },
  {
    subfolder: 'Khaalid_Abdullaah_al-Qahtaanee_192kbps',
    label: 'Khalid Abdullah al-Qahtanee',
  },
  {
    subfolder: 'Yasser_Ad-Dussary_128kbps',
    label: 'Yasser_Ad-Dussary',
  },
  {
    subfolder: 'Nasser_Alqatami_128kbps',
    label: 'Nasser Alqatami',
  },
  {
    subfolder: 'Ali_Hajjaj_AlSuesy_128kbps',
    label: 'Ali Hajjaj Al Suesy',
  },
  {
    subfolder: 'Sahl_Yassin_128kbps',
    label: 'Sahl Yassin',
  },
  {
    subfolder: 'ahmed_ibn_ali_al_ajamy_128kbps',
    label: 'Ahmed Ibn Ali Al Ajamy',
  },
  {
    subfolder: 'translations/besim_korkut_ajet_po_ajet',
    label: 'Besim Korkut (Bosnian)',
  },
  {
    subfolder: 'aziz_alili_128kbps',
    label: 'Aziz Alili',
  },
  {
    subfolder: 'Yaser_Salamah_128kbps',
    label: 'Yaser Salamah',
  },
  {
    subfolder: 'Akram_AlAlaqimy_128kbps',
    label: 'Akram Al Alaqimy',
  },
  {
    subfolder: 'Ali_Jaber_64kbps',
    label: 'Ali Jaber',
  },
  {
    subfolder: 'Fares_Abbad_64kbps',
    label: 'Fares Abbad',
  },
  {
    subfolder: 'translations/urdu_farhat_hashmi',
    label: 'Farhat Hashmi (Urdu word for word translation)',
  },
  {
    subfolder: 'Ayman_Sowaid_64kbps',
    label: 'Ayman Sowaid',
  },
]

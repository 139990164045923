import * as React from 'react'
import Container from '@mui/material/Container'
import Aya from '@components/Aya'
import Basmala from '@components/Basmala'
import Api from '@api'
import SettingContext from '@contexts/SettingContext'
import { getCurrentUser } from '@services/Auth'
import { useSnackbar } from 'notistack'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import Fab from '@mui/material/Fab'
import PauseIcon from '@mui/icons-material/Pause'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
export default function Explorer() {
  const currentUser = getCurrentUser()
  const { currentSetting, updateCurrentSetting } = React.useContext(
    SettingContext
  ) as SettingContextType
  const currentSura = currentSetting?.currentSura ?? 1
  const currentReciter = currentSetting?.currentReciter ?? 'Parhizgar_48kbps'

  function handleSelect(aya: number) {
    if (currentSetting.currentAya != aya) {
      updateCurrentSetting({
        ...currentSetting,
        currentAya: aya,
      })
      if (playing) handlePlay(aya, true)
    }
  }

  const [ayas, setAyas] = React.useState<IAya[]>()

  const [audio] = React.useState(new Audio())
  const [playing, setPlaying] = React.useState(false)
  const [canPause, setCanPause] = React.useState(false)

  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [isLoading, setIsLoading] = React.useState(true)

  audio.onended = () => {
    if (!ayas) return
    if (currentSetting.currentAya < ayas.length)
      handlePlay(currentSetting.currentAya + 1, false)
    else handlePause()
  }

  async function handleLoadData() {
    if (!currentSetting?.translationTypes) return
    if (currentSetting?.translationTypes.length == 0) return
    const translations = currentSetting.translationTypes.join()
    setIsLoading(true)
    try {
      const http = await Api()
      const res = await http.get('quran', {
        params: {
          typeId: 0,
          sura: currentSura,
          translations,
        },
      })
      const data = res.data
      setAyas(data)
      handlePause()
    } catch (error) {
      console.error(error)
      enqueueSnackbar('Error on loading', {
        variant: 'error',
      })
    }
    setIsLoading(false)
  }

  const handlePlay = (aya: number, userInteraction: boolean) => {
    if (!ayas) return
    const arrayItem = ayas[aya - 1]
    const audioFileName = arrayItem.audioFileName

    if (!userInteraction) {
      handleSelect(arrayItem.aya)
      scrollToAya(arrayItem.aya)
    }
    audio.src = `https://www.everyayah.com/data/${currentReciter}/${audioFileName}.mp3`

    const playPromise = audio.play()
    setPlaying(true)

    if (playPromise !== undefined) {
      playPromise
        .then((_) => {
          setCanPause(true)
        })
        .catch((error) => {
          console.log()
        })
    }
  }

  const handleTogglePlay = () => {
    if (playing) handlePause()
    else handlePlay(currentSetting?.currentAya ?? 1, false)
  }

  const handlePause = () => {
    if (canPause) {
      audio.pause()
      audio.src = ''
    }
    setPlaying(false)
    setCanPause(false)
  }

  React.useEffect(() => {
    ;(async () => await handleLoadData())()
  }, [
    currentSetting?.currentSura,
    currentSetting?.translationTypes,
    currentSetting?.isAuthenticated,
  ])

  React.useEffect(() => {
    if (
      currentSetting &&
      currentSetting.currentSura &&
      currentSetting.currentAya
    )
      window.location.hash = `${currentSetting.currentSura}:${currentSetting.currentAya}`
  }, [currentSetting?.currentSura, currentSetting?.currentAya])

  React.useEffect(() => {
    if (playing) handlePlay(currentSetting.currentAya, true)
  }, [currentSetting?.currentReciter])

  React.useEffect(() => {
    if (!isLoading && ayas && currentSetting.currentAya > 0) {
      if (currentSetting.currentAya == 1) window.scrollTo({ top: 0 })
      else scrollToAya(currentSetting.currentAya, 'auto')
    }
  }, [isLoading, currentSetting?.positionChanged])

  const scrollToAya = (aya: number, behavior?: any) => {
    const element = document.getElementById(`aya${aya}`)
    if (!element) return
    const yOffset = -65
    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset
    window.scrollTo({ top: y, behavior: behavior ?? 'smooth' })
  }

  const handleOnGoto = (suraNumber: number, ayaNumber: number) => {
    updateCurrentSetting({
      ...currentSetting,
      currentSura: suraNumber,
      currentAya: ayaNumber,
    })
  }

  return (
    <div>
      <Fab
        sx={{ position: 'fixed', bottom: 16, left: 16, zIndex: 999 }}
        aria-label="pause"
        color="primary"
        onClick={handleTogglePlay}
      >
        {playing ? <PauseIcon /> : <PlayArrowIcon />}
      </Fab>
      <Container maxWidth="md">
        {isLoading ? (
          <Box
            sx={{
              pt: 5,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <CircularProgress sx={{ m: 'auto' }} />
          </Box>
        ) : (
          <React.Fragment>
            {currentSura != 1 && currentSura != 9 && <Basmala />}
            {ayas &&
              ayas.length &&
              ayas.map((r) => {
                return (
                  <div id={`aya${r.aya}`} key={r.aya}>
                    <Aya
                      audioFileName={r.audioFileName}
                      text={r.text}
                      aya={r.aya}
                      sura={r.sura}
                      translations={r.translations}
                      tafsirs={r.tafsirs}
                      isCurrent={r.aya == currentSetting?.currentAya}
                      currentUserEmail={currentUser?.email ?? ''}
                      bookmarked={r.bookmarked}
                      onSelect={handleSelect}
                    />
                  </div>
                )
              })}
          </React.Fragment>
        )}
      </Container>
    </div>
  )
}

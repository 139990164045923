import React from 'react'

import {
  EditorState,
  ContentState,
  convertFromRaw,
  convertToRaw,
  convertFromHTML,
} from 'draft-js'
import Editor from '@draft-js-plugins/editor'
import draftToHtml from 'draftjs-to-html'
import createMentionPlugin from '@draft-js-plugins/mention'
import createInlineToolbarPlugin from '@draft-js-plugins/inline-toolbar'
import '@draft-js-plugins/inline-toolbar/lib/plugin.css'
import '@draft-js-plugins/mention/lib/plugin.css'
import 'draft-js/dist/Draft.css'

import Api from '@api'

const inlineToolbarPlugin = createInlineToolbarPlugin()

const { InlineToolbar } = inlineToolbarPlugin

export default function RemoteMentionEditor({
  rawValue,
  onRawChange,
}): React.ReactElement {
  const ref = React.useRef<Editor>(null)
  const [editorState, setEditorState] = React.useState(() => {
    const blocksFromHTML = convertFromHTML(rawValue)
    const state = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    )

    return EditorState.createWithContent(state)
    //return EditorState.createEmpty()
    // try {
    //   return EditorState.createWithContent(convertFromRaw(rawValue))
    // } catch {
    //   return EditorState.createWithContent(
    //     ContentState.createFromText(rawValue)
    //   )
    // }
  })
  const [open, setOpen] = React.useState(false)
  const [suggestions, setSuggestions] = React.useState([])

  const { MentionSuggestions, plugins } = React.useMemo(() => {
    const mentionPlugin = createMentionPlugin()
    // eslint-disable-next-line no-shadow
    const { MentionSuggestions } = mentionPlugin
    // eslint-disable-next-line no-shadow

    const plugins = [mentionPlugin, inlineToolbarPlugin]
    return { plugins, MentionSuggestions, InlineToolbar }
  }, [])

  const onOpenChange = React.useCallback((_open: boolean) => {
    setOpen(_open)
  }, [])
  const onSearchChange = React.useCallback(({ value }: { value: string }) => {
    // An import statment would break server-side rendering.
    //require('whatwg-fetch') // eslint-disable-line global-require
    // while you normally would have a dynamic server that takes the value as
    // a workaround we use this workaround to show different results
    // const url = 'https://localhost:5001/quran/lookup'
    // fetch(url)
    //   .then((response) => response.json())
    //   .then((data) => {
    //     setSuggestions(data)
    //   })
  }, [])

  const handleOnChange = (e: any) => {
    setEditorState(e)
    const rawContentState = convertToRaw(e.getCurrentContent())
    const hashConfig = {
      trigger: '@',
      separator: ' ',
    }
    const markup = draftToHtml(rawContentState, hashConfig, true, null)
    onRawChange && onRawChange(markup)
  }

  return (
    <div>
      <Editor
        editorState={editorState}
        onChange={handleOnChange}
        plugins={plugins}
        ref={ref}
      />
      <InlineToolbar />
      <MentionSuggestions
        open={open}
        onOpenChange={onOpenChange}
        suggestions={suggestions}
        onSearchChange={onSearchChange}
        onAddMention={() => {
          // get the mention object selected
        }}
      />
    </div>
  )
}

import React from 'react'
import Typography from '@mui/material/Typography'
import TafsirEditDialog from '@components/AyaTafsirEditDialog'
import { getLanguageFont, getLanguageDirection } from '@helper'

export default function AyaTafsir(props: IAyaTafsirProps) {
  const [text, setText] = React.useState(props.text)

  const updateTafsir = (newValue: string) => {
    setText(newValue)
  }

  function convertMarkdownToHTML(text: string): string {
    return text.replace(/{(.*?)}/g, '<b>$1</b>')
  }

  return (
    <div style={{ direction: getLanguageDirection(props.language) }}>
      {props.isEditable && (
        <TafsirEditDialog
          name={props.name}
          text={props.text}
          tafsirTypeId={props.tafsirTypeId}
          language={props.language}
          isEditable={props.isEditable}
          sura={props.sura}
          aya={props.aya}
          onUpdate={updateTafsir}
        />
      )}
      <div
        className="tafsir"
        style={{
          fontFamily: getLanguageFont(props.language),
          direction: getLanguageDirection(props.language),
        }}
        dangerouslySetInnerHTML={{ __html: convertMarkdownToHTML(text) }}
      ></div>
      <Typography
        sx={{
          fontSize: 10,
          fontFamily: getLanguageFont(props.language),
        }}
        className="blockquote-footer"
        color="text.secondary"
        gutterBottom
      >
        {props.name}
      </Typography>
    </div>
  )
}

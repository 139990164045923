import * as React from 'react'
import { styled } from '@mui/material/styles'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import Collapse from '@mui/material/Collapse'
import IconButton, { IconButtonProps } from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import PlayCircleIcon from '@mui/icons-material/PlayCircle'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Divider from '@mui/material/Divider'
import AyaTranslation from '@components/AyaTranslation'
import AyaTafsir from '@components/AyaTafsir'
import PauseIcon from '@mui/icons-material/Pause'
import { lightGreen } from '@mui/material/colors'
import Bookmark from '@components/Bookmark'
interface ExpandMoreProps extends IconButtonProps {
  expand: boolean
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props
  return <IconButton {...other} />
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}))

export default function Aya(props: IAya) {
  const [expanded, setExpanded] = React.useState(false)

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  const toArabicNumber = (v: string) => {
    const arabicNumbers = ['٠', '١', '٢', '٣', '٤', '٥', '٦', '٧', '٨', '٩']
    return v
      .split('')
      .map((c) => arabicNumbers[parseInt(c)])
      .join('')
  }

  return (
    <React.Fragment>
      {props && (
        <React.Fragment>
          <Card style={{ boxShadow: 'none' }} sx={{ mt: 1 }}>
            <CardContent>
              <Typography
                onClick={() => props.onSelect(props.aya)}
                sx={{
                  mb: 2,
                  backgroundColor: props.isCurrent ? lightGreen[100] : 'unset',
                }}
                variant="body1"
                style={{
                  direction: 'rtl',
                  fontFamily: 'hafs',
                  fontSize: '25px',
                }}
              >
                {props.text}
                <span
                  style={{
                    marginLeft: '10px',
                    marginRight: '10px',
                    direction: 'ltr',
                    fontSize: '0.91em',
                    color: '#050',
                  }}
                >
                  &lrm;﴿{toArabicNumber(props.aya.toString())}﴾&rlm;
                </span>
              </Typography>

              {props.translations &&
                props.translations.length > 0 &&
                props.translations.map((r) => (
                  <AyaTranslation
                    key={r.translationTypeId}
                    name={r.name}
                    text={r.text}
                    translationTypeId={r.translationTypeId}
                    language={r.language}
                    isEditable={r.isEditable}
                    sura={props.sura}
                    aya={props.aya}
                  />
                ))}
            </CardContent>
            <CardActions disableSpacing>
              {props.currentUserEmail && (
                <Bookmark
                  sura={props.sura}
                  aya={props.aya}
                  bookmarked={props.bookmarked}
                />
              )}
              <ExpandMore
                expand={expanded}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"
              >
                <ExpandMoreIcon />
              </ExpandMore>
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <CardContent>
                {props.tafsirs &&
                  props.tafsirs.length > 0 &&
                  props.tafsirs.map((r) => (
                    <AyaTafsir
                      key={r.tafsirTypeId}
                      name={r.name}
                      text={r.text}
                      tafsirTypeId={r.tafsirTypeId}
                      language={r.language}
                      isEditable={r.isEditable}
                      sura={props.sura}
                      aya={props.aya}
                    />
                  ))}
              </CardContent>
            </Collapse>
          </Card>
          <Divider />
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

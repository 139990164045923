import React from 'react'
import Typography from '@mui/material/Typography'
import TranslationEditDialog from '@components/AyaTranslationEditDialog'
import { getLanguageFont, getLanguageDirection } from '@helper'

export default function AyaTranslation(props: IAyaTranslationProps) {
  const [text, setText] = React.useState(props.text)

  const updateTranslation = (newValue: string) => {
    setText(newValue)
  }

  function convertMarkdownToHTML(text: string): string {
    //return text.replace(/{(.*?)}/g, '<b>$1</b>')
    return text
      .replaceAll('[', '(')
      .replaceAll(']', ')')
      .replace(/\((.*?)\)/g, '<span class="pr">($1)</span>')
  }

  return (
    <div style={{ direction: getLanguageDirection(props.language) }}>
      {props.isEditable && (
        <TranslationEditDialog
          name={props.name}
          text={props.text}
          translationTypeId={props.translationTypeId}
          language={props.language}
          isEditable={props.isEditable}
          sura={props.sura}
          aya={props.aya}
          onUpdate={updateTranslation}
        />
      )}
      <span
        className="translation"
        style={{
          fontFamily: getLanguageFont(props.language),
        }}
        dangerouslySetInnerHTML={{ __html: convertMarkdownToHTML(text) }}
      ></span>
      <Typography
        sx={{
          fontSize: 10,
          fontFamily: getLanguageFont(props.language),
        }}
        className="blockquote-footer"
        color="text.secondary"
        gutterBottom
      >
        {props.name}
      </Typography>
    </div>
  )
}

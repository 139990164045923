export function getLanguageFont(language: string) {
  if (language === 'Arabic') return 'hafs'
  if (language === 'Persian') return 'Vazir'
  return 'unset'
}

export function getLanguageDirection(language: string) {
  if (language === 'Arabic') return 'rtl'
  if (language === 'Persian') return 'rtl'
  return 'ltr'
}

import CssBaseline from '@mui/material/CssBaseline'
import Box from '@mui/material/Box'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import Footer from '@components/Footer'
import Header from '@components/Header'
import Explorer from '@components/Explorer'
import { SnackbarProvider } from 'notistack'
import '@assets/index.css'
import { SettingProvider } from '@contexts/SettingContext'
// import { TextProvider } from '@contexts/TextContext'

const theme = createTheme()

function App() {
  return (
    <SnackbarProvider maxSnack={3}>
      <SettingProvider>
        {/* <TextProvider> */}
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Header />
          <main className="app">
            <Box
              sx={{
                bgcolor: 'background.paper',
                pt: 8,
                pb: 6,
              }}
            >
              <Explorer />
            </Box>
          </main>
          {/* <Footer /> */}
        </ThemeProvider>
        {/* </TextProvider> */}
      </SettingProvider>
    </SnackbarProvider>
  )
}

export default App

import * as React from 'react'
const SettingContext = React.createContext()

type SettingProviderProps = {
  children: React.ReactNode
}

const SettingProvider: React.FC = (props: SettingProviderProps) => {
  const [currentSetting, setCurrentSetting] = React.useState<ISetting>()

  const updateCurrentSetting = (setting?: ISetting) => {
    // if (setting) localStorage.setItem('setting', JSON.stringify(setting))
    // else localStorage.clear()
    setCurrentSetting(setting)
  }

  function hashSuraAya(): { sura: number; aya: number } {
    let str = window.location.hash
    if (str.startsWith('#')) str = str.substring(1)
    str = str.trim()
    const ary = str.split(':')
    if (ary.length == 2)
      return { sura: parseInt(ary[0]), aya: parseInt(ary[1]) }
    return { sura: 1, aya: 1 }
  }

  React.useEffect(() => {
    // const loggedInSetting = localStorage.getItem('setting')
    // if (loggedInSetting) {
    //   const foundSetting = JSON.parse(loggedInSetting)
    //   setCurrentSetting(foundSetting)
    // } else {
    //   setCurrentSetting({
    //     translationTypes: [6, 35],
    //   })
    // }

    const h = hashSuraAya()

    setCurrentSetting({
      translationTypes: [6, 35],
      currentSura: h?.sura ?? 1,
      currentAya: h?.aya ?? 1,
    })
  }, [])

  return (
    <SettingContext.Provider
      value={{
        currentSetting,
        updateCurrentSetting,
      }}
    >
      {props.children}
    </SettingContext.Provider>
  )
}

export { SettingProvider, SettingContext }

export default SettingContext

import * as React from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import { styled, alpha } from '@mui/material/styles'
import InputBase from '@mui/material/InputBase'
import SettingContext from '@contexts/SettingContext'
import Popper from '@mui/material/Popper'

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('xs')]: {
    marginLeft: theme.spacing(1),
    width: 200,
  },
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 300,
  },
}))

const SearchIconWrapper = styled('div')(({ theme }) => ({
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  alignItems: 'center',
  justifyContent: 'center',
  [theme.breakpoints.up('xs')]: {
    display: 'none',
    padding: theme.spacing(0, 0),
  },
  [theme.breakpoints.up('sm')]: {
    display: 'none',
    padding: theme.spacing(0, 2),
  },
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(0, 2),
    display: 'flex',
  },
}))

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('xs')]: {
      paddingLeft: theme.spacing(1),
    },
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(1),
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: `calc(1em + ${theme.spacing(6)})`,
    },
  },
}))

const CustomPopper = function (props: any) {
  return <Popper {...props} style={{ width: 300 }} placement="bottom-start" />
}

export default function SuraSelector() {
  const { currentSetting, updateCurrentSetting } = React.useContext(
    SettingContext
  ) as SettingContextType

  const [value, setValue] = React.useState<any | null>(suras[0])

  React.useEffect(() => {
    if (currentSetting?.currentSura > 0)
      setValue(suras[currentSetting?.currentSura - 1])
  }, [currentSetting?.currentSura])

  const handleChange = (newValue: any) => {
    const selectedSura = newValue?.id ?? 1
    setValue(newValue)
    updateCurrentSetting({
      ...currentSetting,
      currentSura: selectedSura,
      currentAya: 1,
    })
  }
  return (
    <Search>
      <SearchIconWrapper>Sura:</SearchIconWrapper>
      <Autocomplete
        freeSolo
        disableClearable
        autoHighlight
        clearOnEscape
        selectOnFocus
        size="small"
        id="combo-box-sura"
        options={suras}
        PopperComponent={CustomPopper}
        sx={{ width: 1 }}
        value={value}
        onChange={(event: any, newValue: any) => {
          handleChange(newValue)
        }}
        renderInput={(params) => {
          const { InputLabelProps, InputProps, ...rest } = params
          return (
            <StyledInputBase
              placeholder="Sura"
              inputProps={{ 'aria-label': 'sura' }}
              {...params.InputProps}
              {...rest}
            />
          )
        }}
      />
    </Search>
  )
}

const suras = [
  {
    id: 1,
    label: '1. The Opening - الفاتحة',
  },
  {
    id: 2,
    label: '2. The Cow - البقرة',
  },
  {
    id: 3,
    label: '3. The Family of Imraan - آل عمران',
  },
  {
    id: 4,
    label: '4. The Women - النساء',
  },
  {
    id: 5,
    label: '5. The Table - المائدة',
  },
  {
    id: 6,
    label: '6. The Cattle - الأنعام',
  },
  {
    id: 7,
    label: '7. The Heights - الأعراف',
  },
  {
    id: 8,
    label: '8. The Spoils of War - الأنفال',
  },
  {
    id: 9,
    label: '9. The Repentance - التوبة',
  },
  {
    id: 10,
    label: '10. Jonas - يونس',
  },
  {
    id: 11,
    label: '11. Hud - هود',
  },
  {
    id: 12,
    label: '12. Joseph - يوسف',
  },
  {
    id: 13,
    label: '13. The Thunder - الرعد',
  },
  {
    id: 14,
    label: '14. Abraham - ابراهيم',
  },
  {
    id: 15,
    label: '15. The Rock - الحجر',
  },
  {
    id: 16,
    label: '16. The Bee - النحل',
  },
  {
    id: 17,
    label: '17. The Night Journey - الإسراء',
  },
  {
    id: 18,
    label: '18. The Cave - الكهف',
  },
  {
    id: 19,
    label: '19. Mary - مريم',
  },
  {
    id: 20,
    label: '20. Taa-Haa - طه',
  },
  {
    id: 21,
    label: '21. The Prophets - الأنبياء',
  },
  {
    id: 22,
    label: '22. The Pilgrimage - الحج',
  },
  {
    id: 23,
    label: '23. The Believers - المؤمنون',
  },
  {
    id: 24,
    label: '24. The Light - النور',
  },
  {
    id: 25,
    label: '25. The Criterion - الفرقان',
  },
  {
    id: 26,
    label: '26. The Poets - الشعراء',
  },
  {
    id: 27,
    label: '27. The Ant - النمل',
  },
  {
    id: 28,
    label: '28. The Stories - القصص',
  },
  {
    id: 29,
    label: '29. The Spider - العنكبوت',
  },
  {
    id: 30,
    label: '30. The Romans - الروم',
  },
  {
    id: 31,
    label: '31. Luqman - لقمان',
  },
  {
    id: 32,
    label: '32. The Prostration - السجدة',
  },
  {
    id: 33,
    label: '33. The Clans - الأحزاب',
  },
  {
    id: 34,
    label: '34. Sheba - سبإ',
  },
  {
    id: 35,
    label: '35. The Originator - فاطر',
  },
  {
    id: 36,
    label: '36. Yaseen - يس',
  },
  {
    id: 37,
    label: '37. Those drawn up in Ranks - الصافات',
  },
  {
    id: 38,
    label: '38. The letter Saad - ص',
  },
  {
    id: 39,
    label: '39. The Groups - الزمر',
  },
  {
    id: 40,
    label: '40. The Forgiver - غافر',
  },
  {
    id: 41,
    label: '41. Explained in detail - فصلت',
  },
  {
    id: 42,
    label: '42. Consultation - الشورى',
  },
  {
    id: 43,
    label: '43. Ornaments of gold - الزخرف',
  },
  {
    id: 44,
    label: '44. The Smoke - الدخان',
  },
  {
    id: 45,
    label: '45. Crouching - الجاثية',
  },
  {
    id: 46,
    label: '46. The Dunes - الأحقاف',
  },
  {
    id: 47,
    label: '47. Muhammad - محمد',
  },
  {
    id: 48,
    label: '48. The Victory - الفتح',
  },
  {
    id: 49,
    label: '49. The Inner Apartments - الحجرات',
  },
  {
    id: 50,
    label: '50. The letter Qaaf - ق',
  },
  {
    id: 51,
    label: '51. The Winnowing Winds - الذاريات',
  },
  {
    id: 52,
    label: '52. The Mount - الطور',
  },
  {
    id: 53,
    label: '53. The Star - النجم',
  },
  {
    id: 54,
    label: '54. The Moon - القمر',
  },
  {
    id: 55,
    label: '55. The Beneficent - الرحمن',
  },
  {
    id: 56,
    label: '56. The Inevitable - الواقعة',
  },
  {
    id: 57,
    label: '57. The Iron - الحديد',
  },
  {
    id: 58,
    label: '58. The Pleading Woman - المجادلة',
  },
  {
    id: 59,
    label: '59. The Exile - الحشر',
  },
  {
    id: 60,
    label: '60. She that is to be examined - الممتحنة',
  },
  {
    id: 61,
    label: '61. The Ranks - الصف',
  },
  {
    id: 62,
    label: '62. Friday - الجمعة',
  },
  {
    id: 63,
    label: '63. The Hypocrites - المنافقون',
  },
  {
    id: 64,
    label: '64. Mutual Disillusion - التغابن',
  },
  {
    id: 65,
    label: '65. Divorce - الطلاق',
  },
  {
    id: 66,
    label: '66. The Prohibition - التحريم',
  },
  {
    id: 67,
    label: '67. The Sovereignty - الملك',
  },
  {
    id: 68,
    label: '68. The Pen - القلم',
  },
  {
    id: 69,
    label: '69. The Reality - الحاقة',
  },
  {
    id: 70,
    label: '70. The Ascending Stairways - المعارج',
  },
  {
    id: 71,
    label: '71. Noah - نوح',
  },
  {
    id: 72,
    label: '72. The Jinn - الجن',
  },
  {
    id: 73,
    label: '73. The Enshrouded One - المزمل',
  },
  {
    id: 74,
    label: '74. The Cloaked One - المدثر',
  },
  {
    id: 75,
    label: '75. The Resurrection - القيامة',
  },
  {
    id: 76,
    label: '76. Man - الانسان',
  },
  {
    id: 77,
    label: '77. The Emissaries - المرسلات',
  },
  {
    id: 78,
    label: '78. The Announcement - النبإ',
  },
  {
    id: 79,
    label: '79. Those who drag forth - النازعات',
  },
  {
    id: 80,
    label: '80. He frowned - عبس',
  },
  {
    id: 81,
    label: '81. The Overthrowing - التكوير',
  },
  {
    id: 82,
    label: '82. The Cleaving - الإنفطار',
  },
  {
    id: 83,
    label: '83. Defrauding - المطففين',
  },
  {
    id: 84,
    label: '84. The Splitting Open - الإنشقاق',
  },
  {
    id: 85,
    label: '85. The Constellations - البروج',
  },
  {
    id: 86,
    label: '86. The Morning Star - الطارق',
  },
  {
    id: 87,
    label: '87. The Most High - الأعلى',
  },
  {
    id: 88,
    label: '88. The Overwhelming - الغاشية',
  },
  {
    id: 89,
    label: '89. The Dawn - الفجر',
  },
  {
    id: 90,
    label: '90. The City - البلد',
  },
  {
    id: 91,
    label: '91. The Sun - الشمس',
  },
  {
    id: 92,
    label: '92. The Night - الليل',
  },
  {
    id: 93,
    label: '93. The Morning Hours - الضحى',
  },
  {
    id: 94,
    label: '94. The Consolation - الشرح',
  },
  {
    id: 95,
    label: '95. The Fig - التين',
  },
  {
    id: 96,
    label: '96. The Clot - العلق',
  },
  {
    id: 97,
    label: '97. The Power, Fate - القدر',
  },
  {
    id: 98,
    label: '98. The Evidence - البينة',
  },
  {
    id: 99,
    label: '99. The Earthquake - الزلزلة',
  },
  {
    id: 100,
    label: '100. The Chargers - العاديات',
  },
  {
    id: 101,
    label: '101. The Calamity - القارعة',
  },
  {
    id: 102,
    label: '102. Competition - التكاثر',
  },
  {
    id: 103,
    label: '103. The Declining Day, Epoch - العصر',
  },
  {
    id: 104,
    label: '104. The Traducer - الهمزة',
  },
  {
    id: 105,
    label: '105. The Elephant - الفيل',
  },
  {
    id: 106,
    label: '106. Quraysh - قريش',
  },
  {
    id: 107,
    label: '107. Almsgiving - الماعون',
  },
  {
    id: 108,
    label: '108. Abundance - الكوثر',
  },
  {
    id: 109,
    label: '109. The Disbelievers - الكافرون',
  },
  {
    id: 110,
    label: '110. Divine Support - النصر',
  },
  {
    id: 111,
    label: '111. The Palm Fibre - المسد',
  },
  {
    id: 112,
    label: '112. Sincerity - الإخلاص',
  },
  {
    id: 113,
    label: '113. The Dawn - الفلق',
  },
  {
    id: 114,
    label: '114. Mankind - الناس',
  },
]

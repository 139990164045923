import * as React from 'react'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import EditIcon from '@mui/icons-material/Edit'
import { getLanguageDirection } from '@helper'
import Api from '@api'
import { useSnackbar } from 'notistack'
import LinearProgress from '@mui/material/LinearProgress'
import Speech from '@components/Speech'

export default function TranslationEditDialog(props: IAyaTranslationEditProps) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [open, setOpen] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(true)
  const [isSubmitting, setIsSubmitting] = React.useState(false)

  const [text, setText] = React.useState<string>('')
  const inputRef = React.useRef()

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  async function handleLoadData() {
    try {
      setIsLoading(true)
      const http = await Api()
      const res = await http.get(`translations`, {
        params: {
          translationTypeId: props.translationTypeId,
          sura: props.sura,
          aya: props.aya,
        },
      })
      const data = res.data
      setText(data.text)
      setIsLoading(false)
    } catch (error) {
      console.error(error)
      enqueueSnackbar('Error on loading translation', {
        variant: 'error',
      })
    }
  }

  React.useEffect(() => {
    ;(async () => {
      if (open) await handleLoadData()
    })()
  }, [open])

  async function handleSaveData() {
    setIsSubmitting(true)
    try {
      const http = await Api()
      const res = await http.post('translations', {
        translationTypeId: props.translationTypeId,
        sura: props.sura,
        aya: props.aya,
        text,
      })

      enqueueSnackbar('Saved successfully.', {
        variant: 'success',
      })
      setIsSubmitting(false)
      props.onUpdate && props.onUpdate(text)
      handleClose()
    } catch (error) {
      console.error(error)
      enqueueSnackbar('Error on saving translation', {
        variant: 'error',
      })
      setIsSubmitting(false)
    }
  }

  return (
    <React.Fragment>
      <IconButton aria-label="edit" onClick={handleClickOpen} size="small">
        <EditIcon />
      </IconButton>
      {open && (
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Edit Translation</DialogTitle>
          {isLoading ? (
            <LinearProgress />
          ) : (
            <React.Fragment>
              <DialogContent>
                <DialogContentText>
                  Just you have a permission to edit this translation.
                </DialogContentText>
                <TextField
                  sx={{
                    direction: getLanguageDirection(props.language),
                  }}
                  margin="dense"
                  id="text"
                  inputRef={inputRef}
                  label="Translation"
                  multiline={true}
                  rows={5}
                  fullWidth
                  variant="standard"
                  value={text}
                  onChange={(e) => setText(e.target.value)}
                />
                <Speech el={inputRef} />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleSaveData} disabled={isSubmitting}>
                  Save
                </Button>
              </DialogActions>
            </React.Fragment>
          )}
        </Dialog>
      )}
    </React.Fragment>
  )
}
